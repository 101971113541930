<template>
    <div class="main-content">
        <el-form ref="ossform" :model="form" label-width="200px"  :rules="rule" class="oss-form">
            <el-form-item label="网站应用名称" prop="webName">
                <el-input v-model="form.webName"></el-input>
            </el-form-item>
            <el-form-item  label="店铺运营模拟的次数" prop="count">
                <el-input-number :controls="false" v-model.number="form.count" :min="0" :max="99" placeholder="0则为不限制次数" />
            </el-form-item>
            <el-form-item>
                <el-button  type="primary" @click="submitForm('ossform')">提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "WebSetting",
    data() {
        return {
            form: {
                webName: '',
                count: 0
            },
            rule: {
                webName: [
                    {required: true, message: '请输入网站应用名称', trigger: 'blur'},
                    {min: 5, max: 50, message: '长度在 5 到 50 个字符', trigger: 'blur'}
                ],
                count: [
                    {required: true, message: '请输入店铺运营模块模拟操作结束的次数', trigger: 'blur'}
                ]
            }
        }
    },
    created() {
        this.settings();
    },
    methods: {
        settings() {
            this.$http.asyncGet(this.$api.web, {}).then(res => {
                if (res.code) {
                    this.form.webName = res.data.name.trim();
                    this.form.count = Number(res.data.count)
                }
            });
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let formData = new FormData();
                    formData.append('web_name', this.form.webName.trim());
                    formData.append('count', this.form.count)
                    this.$http.asyncPost(this.$api.web_setting, formData).then(res => {
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: res.msg,
                                duration: 2000,
                                onClose: () => {
                                    this.settings();
                                }
                            });
                        }
                    }).catch(err => {
                        this.$message.error('请求异常');
                        console.log(err);
                    });
                } else {
                    console.log('shibai');
                    return false;
                }
            });
        }

    }
}
</script>

<style scoped lang="scss">
.main-content {
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    .oss-form {
        width: 800px;
    }
}

</style>